<template>
  <table>
    <thead>
    <tr>
      <th class="center-align" style="width: 100px">ID</th>
      <th>Имя</th>
      <th>Роль</th>
      <th>Отдел</th>
      <th>Должность</th>
      <th>Бригада</th>
    </tr>
    </thead>

    <tbody>
    <tr v-for="(record, idx) of records" :key="record.id">
      <td class="center-align">
        <router-link :to="{path: '/users/' + record.id}"
                     v-tooltip="'Открыть'"
                     class="btn-small btn blue darken-4">
          {{record.id}}
        </router-link>
      </td>
      <td>{{record.last_name}} {{record.first_name}} {{record.middle_name}}</td>
      <td>
        <section v-if="record.role === 'owner'">Владелец</section>
        <section v-else-if="record.role === 'admin'">Администратор</section>
        <section v-else-if="record.role === 'dispatcher'">Диспетчер</section>
        <section v-else-if="record.role === 'performer'">Исполнитель</section>
      </td>
      <td>
        <span v-if="record.department">{{record.department.name}}</span>
        <span v-else>Нет</span>
      </td>
      <td>{{record.position}}</td>
      <td>
        <span v-if="record.team">{{record.team.name}}</span>
        <span v-else>Нет</span>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    records: {
      required: true,
      type: Array
    }
  }
}
</script>
