<template>
  <div>
    <div class="page-title">
      <h3>Пользователи</h3>
      <span>
        <div class="input-field">
          <i class="material-icons prefix">search</i>
          <input id="search" type="text" v-model="search">
          <label for="search">Поиск</label>
        </div>
      </span>
    </div>

    <Loader v-if="loading"/>

    <p class="center" v-else-if="!records.length">
      {{'Нет записей'}}
    </p>

    <section v-else>
      <UsersTable :records="items"/>

      <Paginate
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler"
        :prev-text="'Назад'"
        :next-text="'Вперед'"
        :container-class="'pagination'"
        :page-class="'waves-effect'"
      />
    </section>
  </div>
</template>

<script>
  import paginationMixin from '@/mixins/pagination.mixin'
  import UsersTable from '@/components/UsersTable'

  export default {
    name: 'users',
    metaInfo() {
      return {
        title: this.$title('Пользователи')
      }
    },
    mixins: [paginationMixin],
    data: () => ({
      loading: true,
      records: [],
      search: '',
      searchDebounce: '',
      currentPage: 1,
      pageSize: 50,
    }),
    async mounted() {
      this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1

      await this.loadResult()
      this.loading = false
    },
    watch: {
      search: function (val) {
        if (val !== '' && val.length < 3) {
          return
        }

        let DELAY = 2000;
        return this.$inputThrottle(this.applyFilter, DELAY);
      }
    },
    methods: {
      async applyFilter() {
        this.currentPage = 1

        if (this.$route.query.page && parseInt(this.$route.query.page) !== this.currentPage) {
          this.$router.push(`${this.$route.path}?page=${this.currentPage}`)
        }

        await this.loadResult()
      },
      async setPaginationPage(num) {
        this.currentPage = num
        await this.loadResult()
      },
      async loadResult() {
        const response = await this.$store.dispatch('fetchUsers', {search: this.search, page: this.currentPage, size: this.pageSize})
        this.records = response.users || []
        this.setupPagination(this.setPaginationPage, this.currentPage, 50, this.records, response.count)
      },
    },
    components: {
      UsersTable
    }
  }
</script>
